import { mapGetters } from 'vuex'
import { DEBUG_MODE, DEV_MODE } from '@/config/env'

export default {
  computed: {
    ...mapGetters(['isReview']),
    showReview() {
      return DEV_MODE === 'local' || DEBUG_MODE || this.isReview
    }
  },
  watch: {
    isReview() {
      this.$router.push('/')
    }
  }
}
