<template>
    <div class="ajax-error-warning container-fluid" v-if="error && !adBlockDetected && hasProduct">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-danger" v-html="notificationMessage"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .ajax-error-warning {
        padding: 15px 30px 0;

        .alert {
            margin-bottom: 0;
            padding: 15px;
        }
    }
</style>

<script>
    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [organization],

        data() {
            return {
                error: false,
                adBlockDetected: false,
                hasProduct: true,
                hasIntegration: true,
                errorMessage: '',
                statusCode: 0
            }
        },

        computed: {
            notificationMessage() {
                let message = $t('warnings.ajax.message')

                if(!this.isFreemium){
                    message += ': Status code: ' + this.statusCode + ': ' + this.errorMessage
                }
                return message
            }
        },

        mounted() {
            eventHub.$on('ajax-error', this.ajaxError)
            eventHub.$on('ad-block-detected', this.setAdBlockDetected)
            eventHub.$on('has-not-product', this.hasNotProduct)
            eventHub.$on('has-not-integration', this.hasNotIntegration)
            eventHub.$on('reset-error', this.resetError)
        },

        destroyed() {
            eventHub.$off('ajax-error', this.ajaxError)
            eventHub.$off('ad-block-detected', this.setAdBlockDetected)
            eventHub.$off('has-not-product', this.hasNotProduct)
            eventHub.$off('has-not-integration', this.hasNotIntegration)
        },

        methods: {
            ajaxError(response) {
                this.errorMessage = response.data.message
                this.statusCode = response.data.code
                this.error = true
            },

            setAdBlockDetected() {
                this.adBlockDetected = true
            },

            hasNotProduct() {
                this.hasProduct = false
            },

            hasNotIntegration() {
                this.hasIntegration = false
            },

            resetError(){
                this.error = false
            }
        },

        watch: {
            '$route' (to, from) {
                this.error = false
                this.adBlockDetected = false
                this.hasProduct = true
                this.hasIntegration = true
            }
        }
    }
</script>
