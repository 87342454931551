<template>
	<nav>
		<!-- OVERVIEW -->
		<router-link :to="{ name: 'frontend.overview' }">
			<span class="icon"><i class="far fa-chart-pie fa-fw" /></span>
			<p>{{$t('navigation.overview')}}</p>
		</router-link>

		<!-- INQUIRIES -->
		<a v-if="! isFinland"
		   class="toggle"
		   @click.prevent="toggleMenu('inquiries')"
		   :class="{ toggled: showInquiries, 'router-link-active': showInquiries }">
			<span class="icon"><i class="far fa-comment-dots fa-fw" /></span>
			<p>{{$t('navigation.latestInquiries')}}</p>
			<span class="toggle-icon"><i class="far fa-caret-down" /></span>
		</a>
		<ul class="toggle-nav" :class="{visible: showInquiries }">
			<li>
				<router-link :to="{ name: 'frontend.inquiries' }">
					<p>{{$t('navigation.latestInquiries')}}</p>
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'frontend.contacts' }">
					<p>{{$t('navigation.contacts')}}</p>
				</router-link>
			</li>
		</ul>

		<!-- INTEGRATIONS: Hidden on desktop -->
		<router-link class="visible-on-mobile" :to="{ name: 'frontend.settings.integrations' }">
			<span class="icon"><i class="far fa-link fa-fw" /></span>
			<p>{{$t('navigation.integrations')}}</p>
		</router-link>

		<!-- REVIEWS -->
		<router-link :to="{ name: 'frontend.reviews' }">
			<span class="icon"><i class="far fa-heart fa-fw" /></span>
			<p>{{$t('navigation.reputation')}}</p>
		</router-link>

		<!-- FREEMIUM -->
		<router-link v-if="language.short === 'da' && isFreemium" :to="{ name: 'frontend.services' }">
			<span class="icon"><i class="far fa-images fa-fw" /></span>
			<p>{{$t('navigation.services')}}</p>
		</router-link>

		<router-link :to="{ name: 'copilot' }">
			<span class="icon"><i class="far fa-microchip-ai fa-fw" /></span>
			<p>Co-pilot <sup class="plus"/></p>
		</router-link>

		<router-link :to="{ name: 'chatbot' }">
			<span class="icon"><i class="far fa-message-bot fa-fw"/></span>
			<p>Chat bot</p>
		</router-link>

		<router-link :to="{ name: 'billing' }">
			<span class="icon"><i class="far fa-plus fa-fw" /></span>
			<p>{{ $t('billing.label') }}</p>
		</router-link>

		<hr>

		<!-- << REPORTING >> -->
		<h5 class="headline">{{$t('navigation.statistics.title')}}</h5>

		<!-- ANALYTICS -->
		<router-link :to="{ name: 'frontend.reporting.visitors' }">
			<span class="icon"><i class="far fa-chart-area fa-fw" /></span>
			<p>{{$t('navigation.statistics.visitors')}}</p>
		</router-link>

		<!-- COMPETITORS -->
		<router-link v-if="customer.showCompetitors"
					 :to="{ name: 'frontend.reporting.competitors' }">
			<span class="icon"><i class="far fa-users fa-fw" /></span>
			<p>{{$t('navigation.statistics.competitors')}}</p>
		</router-link>

		<!-- CONVERSIONS -->
		<router-link :to="{ name: 'frontend.reporting.conversions' }">
			<span class="icon"><i class="far fa-filter fa-fw" /></span>
			<p>{{$t('navigation.statistics.alternativeConversions')}}</p>
		</router-link>

		<!-- ECONOMY -->
		<router-link :to="{ name: 'frontend.reporting.economy' }">
			<span class="icon"><i class="far fa-piggy-bank fa-fw" /></span>
			<p>{{$t('navigation.statistics.economy')}}</p>
		</router-link>

		<!-- DATA STUDIO -->
		<a v-if="customer && customer.dataStudioLink"
		   :href="customer.dataStudioLink"
		   target="_blank"
		   rel="noopener noreferrer">
			<span class="icon"><i class="datastudio-icon"></i></span>
			<p>{{$t('navigation.statistics.dataStudio')}}</p>
		</a>

		<!-- SEO -->
		<router-link :to="{ name: 'frontend.reporting.seo' }">
			<span class="icon"><i class="far fa-chart-line fa-fw" /></span>
			<p>{{$t('navigation.statistics.seo')}}</p>
		</router-link>

		<!-- GOOGLE ADS -->
		<template>
			<a class="toggle"
			   @click.prevent="toggleMenu('google-ads')"
			   :class="{ toggled: showGoogle, 'router-link-active': showGoogle }">
				<span class="icon"><i class="fab fa-google fa-fw" /></span>
				<p>Google</p>
				<span class="toggle-icon"><i class="fas fa-caret-down" /></span>
			</a>
			<ul class="toggle-nav" :class="{ visible: showGoogle }">
				<li>
					<router-link :to="{ name: 'frontend.googleAds.ads' }">
						<p>{{ $t('navigation.google.ads') }}</p>
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'frontend.googleAds.posts.overview' }">
						<p>{{ $t('navigation.google.posts') }}</p>
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'frontend.googleAds.profile.page', params: {page: 'overview'} }">
						<p>{{ $t('navigation.google.profile') }}</p>
					</router-link>
				</li>
			</ul>
		</template>

		<!-- META -->
		<a class="toggle"
		   @click.prevent="toggleMenu('meta')"
		   :class="{ toggled: showMeta, 'router-link-active': showMeta }">
			<span class="icon"><i class="fab fa-meta fa-fw" /></span>
			<p>Meta</p>
			<span class="toggle-icon"><i class="fas fa-caret-down" /></span>
		</a>

		<ul class="toggle-nav" :class="{visible: showMeta }">
			<li>
				<router-link :to="{ name: 'frontend.reporting.meta.ads' }">
					<p>Meta Ads</p>
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'frontend.reporting.meta.facebook.page' }">
					<p>Facebook Page</p>
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'frontend.reporting.meta.instagram.page' }">
					<p>Instagram Page</p>
				</router-link>
			</li>
		</ul>

		<!-- LINKEDIN -->
		<a class="toggle"
		   @click.prevent="toggleMenu('linkedin')"
		   :class="{ toggled: showLinkedin, 'router-link-active': showLinkedin }">
			<span class="icon"><i class="fab fa-linkedin fa-fw" /></span>
			<p>{{$t('navigation.linkedin.title')}} <sup class="beta"/></p>
			<span class="toggle-icon"><fa icon="caret-down"/></span>
		</a>

		<ul class="toggle-nav" :class="{visible: showLinkedin }">
			<li>
				<router-link :to="{ name: 'frontend.reporting.linkedin.ads' }">
					<p>{{ $t('navigation.linkedin.ads') }} <sup class="beta"/></p>
				</router-link>
			</li>
			<li v-if="1 === 3">
				<router-link :to="{ name: 'frontend.reporting.linkedin.social' }">
					<p>{{ $t('navigation.linkedin.social') }}</p>
				</router-link>
			</li>
		</ul>

		<!-- NEWSLETTERS -->
		<a v-if="hasHealthyIntegration('MailChimp') || hasHealthyIntegration('Klaviyo')"
		   class="toggle"
		   @click.prevent="toggleMenu('newsletters')"
		   :class="{ toggled: showNewsletters, 'router-link-active': showNewsletters }">
			<span class="icon"><i class="far fa-envelope fa-fw" /></span>
				<p>{{ $t('navigation.statistics.newsletters') }}</p>
			<span class="toggle-icon"><i class="fas fa-caret-down"/></span>
		</a>

		<ul v-if="hasHealthyIntegration('MailChimp') || hasHealthyIntegration('Klaviyo')"
			class="toggle-nav"
			:class="{visible: showNewsletters }">
			<li v-if="hasHealthyIntegration('MailChimp')">
				<router-link :to="{ name: 'frontend.newsletters' }">
					<p>MailChimp</p>
				</router-link>
			</li>
			<li v-if="hasHealthyIntegration('Klaviyo')">
				<router-link :to="{ name: 'frontend.klaviyo' }">
					<p>Klaviyo</p>
				</router-link>
			</li>
		</ul>

		<hr>

		<div class="visible-on-mobile">
			<hr>
			<!-- << PROFILE >> -->
			<h5 class="headline">{{$t('navigation.profileHeadline')}}</h5>

			<!-- PROFILE SETTINGS -->
			<router-link v-if="customer"
						 :to="{name: 'frontend.settings.profile'}">
				<span class="icon"><i class="far fa-user" /></span>
				<p>{{customer.domain}}</p>
			</router-link>

			<slot name="mobile"/>
		</div>
	</nav>
</template>

<script>
import {mapGetters} from 'vuex'
import hasIntegration from '@/mixins/integrations/hasIntegration'
import organization from '@/mixins/customer/organization'
import development from '@/mixins/development'

export default {
	mixins: [hasIntegration, organization, development],
	name: 'ClientNavigation',
	data(){
		return {
			showAi: false,
			showGoogle: false,
			showNewsletters: false,
			showMeta: false,
			showLinkedin: false,
			showInquiries: false
		}
	},
	methods: {
		toggleMenu(menu) {
			switch (menu) {
				case 'ai':
					this.showAi = ! this.showAi
					return

				case 'google-ads':
					this.showGoogle = ! this.showGoogle
					return

				case 'linkedin':
					this.showLinkedin = ! this.showLinkedin
					return

				case 'meta':
					this.showMeta = ! this.showMeta
					return

				case 'inquiries':
					this.showInquiries = ! this.showInquiries
					return
				case 'newsletters':
					this.showNewsletters = ! this.showNewsletters
					return
			}
		},
	},
	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),
		...mapGetters('customer', {
			customer: 'getCustomer',
		}),
		...mapGetters({
			language: 'getLanguage'
		}),
		...mapGetters('ghost', {
			ghostUserIdentity: 'getGhostUserIdentity',
		}),

		isFinland() {
			return !! this.customer && !! this.customer.country && this.customer.country.code === 'FI'
		},

		isInquiries() {
			return this.$route.name.includes('inquiries') || this.$route.name.includes('contacts')
		},

		isFacebook() {
			return this.$route.name.includes('facebook')
		},

		isNewsletters() {
			return this.$route.name.includes('newsletters')
		},

		isGoogle() {
			return this.$route.name.includes('googleAdwords')
		},

		isGoogleAds() {
			return this.$route.name.includes('googleAds')
		},

		isFacebookAccount() {
			return this.user.email.includes('facebook')
		}
	}
}
</script>
